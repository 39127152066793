import {encodeRequestParameters, formatData} from "@/services/request";
import {versionError} from "@/services/formats";
import httpClient from "@/services/httpClient";


export function saveVersionError(data) {
  data = formatData(versionError, data)
  if (data === false) {
    throw '500'
  }
  const params = encodeRequestParameters(data)
  return httpClient.post('/versions/log', params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}
